import { useState } from 'react'
import { EditText, EditTextarea } from 'react-edit-text'
import validator from 'validator'
import request from '../../../utils/request'
import { message } from 'antd'   

export default function IPCell({ ip, id }) {
    const [ipChange, setIPChange] = useState(ip)

    const updateIP = () => {
        request('/compute/changeIP', 'POST', {
            id,
            ip: ipChange.trim()
        })
        .then(() => { window.location.reload() })
        .catch(err => message.error(err.message || err))
    }

    return (
        <EditText 
            value={ipChange}
            placeholder='IP Addresses'
            inline={true}
            className='text-white'
            rows={1}
            onChange={e => setIPChange(e.target.value)}
            onSave={() => {
                if(validator.isIP(ipChange, [4])) {
                    updateIP()
                } else {
                    setIPChange(ip)
                    message.error('Invalid IP Address')
                }
            }}
        />
    )
}