import { useEffect, useState } from "react"
import request from '../../../utils/request'
import { Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import Empty from './Empty'
import AddDialog from "./AddDialog"
import { message } from "antd"
import moment from 'moment-timezone'
import 'moment/locale/id'
import { useDebounce } from 'use-debounce'
import ChangeDurationDialog from "./ChangeDurationDialog"
import PerformanceCell from "./PerformanceCell"
import IPCell from "./IPCell"

export default function Compute() {
    const [computes, setComputes] = useState([])
    const [loading, setLoading] = useState(true)
    const [addComputeDialogOpen, setAddComputeDialogOpen] = useState(false)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const [searchDebounced] = useDebounce(search, 700)
    const [moreLoading, setMoreLoading] = useState(false)
    const [changeDurationId, setChangeDurationId] = useState(null)

    const loadComputes = () => {
        setMoreLoading(true)
        request('/compute/computes', 'POST', { page, search })
            .then((data) => {
                let x = [...computes]
                for(const compute of data.computes) {
                    if(x.findIndex(y => y.id === compute.id) < 0) { x.push(compute) }
                }
                setComputes(x)
            })
            .catch(err => message.error(err.message || err))
            .finally(() => {
                setLoading(false)
                setMoreLoading(false)
            })
    }

    useEffect(() => {
        loadComputes()
    }, [searchDebounced])

    useEffect(() => {
        loadComputes()
    }, [])

    const removeCompute = id => {
        if(window.confirm('Anda yakin ingin menghapus compute ini ?')) {
            request('/compute/remove', 'POST', { id })
                .then(() => {
                    window.location.reload()
                })
                .catch(err => message.error(err.message || err))
        }
    }

    return (
        <div className="compute">
            <ChangeDurationDialog 
                open={Boolean(changeDurationId)}
                onClose={() => setChangeDurationId(null)}
                compute={changeDurationId}
                onChanged={compute => {
                    let x = [...computes]
                    const i = x.findIndex(x => x.id === compute.id)
                    if(i >= 0) {
                        x[i] = compute
                        setComputes(x)
                    }
                }}
            />
            <AddDialog open={addComputeDialogOpen} onClose={() => setAddComputeDialogOpen(false)}
                onAdded={() => {
                    window.location.reload()
                }}
            />
            <div className="mx-auto mt-2">
                <div className="flex items-center w-full">
                    <input placeholder="Type Something..." className="outline-none bg-transparent text-white mt-2"
                        value={search}
                        onChange={e => {
                            setSearch(e.target.value)
                            setComputes([])
                            setLoading(true)
                            setPage(1)
                        }}
                    />
                    <button className="bg-gray-100 px-4 py-1 rounded-lg ml-auto mr-0 hover:bg-gray-200" onClick={() => {
                        setAddComputeDialogOpen(true)
                    }}>Add Compute</button>
                </div>
                {loading && (
                    <div className="loading-bar w-fit h-fit absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                        <CircularProgress />
                    </div>
                )}

                {!loading && computes.length < 1 && (
                    <Empty onAddCompute={() => setAddComputeDialogOpen(true)} />
                )}

                {!loading && computes.length > 0 && (
                    <>
                        <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><div className="text-white font-bold">No</div></TableCell>
                                    <TableCell><div className="text-white font-bold">Name</div></TableCell>
                                    <TableCell><div className="text-white font-bold">IP</div></TableCell>
                                    <TableCell><div className="text-white font-bold">Created</div></TableCell>
                                    <TableCell><div className="text-white font-bold">Expired</div></TableCell>
                                    <TableCell><div className="text-white font-bold">Monitor</div></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {computes.map((compute, i) => (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <div className="text-white font-bold">{i + 1}.</div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="text-white">{compute.name}</div>
                                        </TableCell>
                                        <TableCell>
                                            <IPCell id={compute.id} ip={compute.ip} />
                                            {/* <div className="text-white">{compute.ip}</div> */}
                                        </TableCell>
                                        <TableCell>
                                            <div className="text-white">
                                                <div className="at font-bold whitespace-nowrap">{moment(compute.createdAt * 1000).tz("Asia/Jakarta").format('dddd, DD MMM YYYY HH:mm')}</div>
                                                <div className="by text-gray-300">By <u className="italic">@{compute.createdBy}</u></div>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="text-white">
                                                <div className="at font-bold whitespace-nowrap">{moment(compute.expiredAt * 1000).tz("Asia/Jakarta").format('dddd, DD MMM YYYY HH:mm')}</div>
                                                {compute.isExpired && (
                                                    <div className="font-bold text-red-500">EXPIRED</div>
                                                )}
                                                {!compute.isExpired && (
                                                    <div className="font-bold text-green-500 capitalize">
                                                        {moment(compute.expiredAt * 1000).tz("Asia/Jakarta").fromNow()}
                                                    </div>
                                                )}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <PerformanceCell ip={compute.ip} />
                                        </TableCell>
                                        <TableCell>
                                            <div className="flex items-center gap-1">
                                                <button className="text-gray-200 bg-blue-500 rounded-lg whitespace-nowrap px-2 py-1 shadow-lg hover:bg-blue-600"
                                                    onClick={() => setChangeDurationId(compute)}
                                                >Extend</button>
                                                <button className="text-gray-200 bg-red-500 rounded-lg whitespace-nowrap px-2 py-1 shadow-lg hover:bg-red-600"
                                                    onClick={() => removeCompute(compute.id)}
                                                >Delete</button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        </TableContainer>
                        <button className="text-white flex w-fit mx-auto mt-4 hover:underline" onClick={() => {
                            if(!moreLoading) {
                                setPage(x => x + 1)
                                loadComputes()
                            }
                        }}>{moreLoading ? '...' : 'Load More'}</button>
                    </>
                )}
            </div>
        </div>
    )
}