import { useEffect, useRef, useState } from "react"
import request from "../../../utils/request"
import cpu from '../../../assets/icons/cpu.png'
import storage from '../../../assets/icons/storage.png'
import memory from '../../../assets/icons/ram.png'  
import network from '../../../assets/icons/network.png'

function formatMBtoGB(mb) {
    if (mb < 0) {
        throw new Error('Megabytes cannot be negative');
    }

    const gb = mb / 1024;
    return `${gb.toFixed(1)} GB`;
}

export default function PerformanceCell({ ip }) {

    const [data, setData] = useState(null)
    const refreshInterval = useRef(null)

    const refreshData = () => {
        request('/compute/performance?ip=' + ip, 'GET')
            .then(data => {
                setData(data)
            })
            .catch(err => console.error(err.message || err))
    }

    useEffect(() => {
        refreshData()
        refreshInterval.current = setInterval(refreshData, 3000)

        return () => {
            if(refreshInterval.current) {
                clearInterval(refreshInterval.current)
            }
            refreshInterval.current = null
        }
    }, [])




    return data !== null && (
        <div className="flex items-center gap-2">
            {/* Cpu display */}
            <div className="flex items-center gap-1">
                <img src={cpu} alt="cpu" className="w-6" />
                <div className="text-slate-100 whitespace-nowrap">{ data.cpu.cores } Core - { data.cpu.percentUsage.toFixed(0) }%</div>
            </div>
            {/* End of cpu display */}
            <div className="bg-gray-300 rounded-lg h-5 w-0.5" /> 
            {/* Storage */}
            <div className="flex items-center gap-1">
                <img src={storage} alt="storage" className="w-6" />
                <div className="text-slate-100 whitespace-nowrap">{ formatMBtoGB(data.storage.used) } / { formatMBtoGB(data.storage.total) }</div>
            </div>
            {/* End of storage */}
            {/* <div className="bg-gray-300 rounded-lg h-5 w-0.5 ml-6" />  */}
            {/* Network */}
            {/* <div className="flex items-center gap-1">
                <img src={network} alt="network" className="w-6" />
                <div className="text-slate-100 whitespace-nowrap">{ data.network.download } MBps DN - { data.network.upload } MBps UP</div>
            </div> */}
            <div className="bg-gray-300 rounded-lg h-5 w-0.5 " /> 
            {/* End of network */}
            <div className="flex items-center gap-1">
                <img src={memory} alt="network" className="w-6" />
                <div className="text-slate-100 whitespace-nowrap">{ formatMBtoGB(data.memory.used) } / { formatMBtoGB(data.memory.total) }</div>
            </div>
        </div>
    )
}